import http from '@/utils/services/http'

export const checaDisponibilidade = () => {
  let url = '/api/integra/resale/checa-disponibilidade'
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const consultaImoveis = (filters) => {
  let url = '/api/integra/resale/consultaImoveis'+filters
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const relacionaItensLeilao = (leilao, data) => {
  let url = `/api/integra/resale/leilao/${leilao}/relacionaBensLeilao`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const atualizaLotes = (leilao) => {
  let url = `/api/integra/resale/leilao/${leilao}/atualizaLotes`
  return http.post(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const commandLogBuffer = (filter) => {
  let url = `/app/log/find`
  return http.get(url + `?` + filter)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

