<script>
/* eslint-disable */

import {checaDisponibilidade} from "@/domain/integra/services/resale";

export default {
  name: 'WSResale',
  inject: ['page'],
  mixins: [],
  components: {},
  data() {
    return {
      loading: true,
      disponibilidade: null
    }
  },
  computed: {},
  mounted() {
    this.page.setActive('basic')
    document.querySelector('.u-erp-layout-container').classList.add('app-globalconfig')
    this.checaDisponibilidade()
  },
  beforeDestroy() {
    document.querySelector('.u-erp-layout-container').classList.remove('app-globalconfig')
  },
  methods: {
    checaDisponibilidade () {
      checaDisponibilidade()
      .then(({data}) => {
        this.loading = false
        this.disponibilidade = data
        console.log(data)
      })
      .catch(error => {
        this.alertApiError(error)
      })
    }
  }
}
</script>

<template>
  <div class="flex col-grow no-wrap globalconfigs-container">
    <transition name="slide" appear>
      <div class="transition-generic w-full">
        <div class="global-config-container">
          <div class="m-t m-b-lg">
            <!--            <h2 class="i-title">Consulta de veículos disponíveis para leilão</h2>
                        <p class="m-b-xxl">Integração com o Resale</p>-->
            <h2 class="i-title">Olá, seja bem vindo(a) ao serviço de integração com o Resale</h2>

            <div class="m-t-lg">
              <div v-if="loading">
                <i class="fa fa-spin fa-spinner"></i> Aguarde enquanto verificamos a disponibilidade do ambiente.
              </div>
              <div v-if="disponibilidade">
                Integração Ativa: <span v-if="disponibilidade.ativo">Sim</span><span v-else>Não</span><br>
                <div class="m-t-lg">
                  <div><e-btn label="Consultar Imoveis disponíveis" @click="$router.push({name: 'integra.resale.consultaImoveis'})" /></div>
<!--                  <div class="m-t-sm"><e-btn label="Atualiza Lotes" /></div>
                  <div class="m-t-sm"><e-btn label="Atualiza Arrematantes" /></div>-->
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
